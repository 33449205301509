import {
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_ALL_CARDS_FAIL,
  GET_ALL_CARDS_REQUEST,
  GET_ALL_CARDS_SUCCESS,
  GET_CARD_FAIL,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  GET_CSV_LIST_FAIL,
  GET_CSV_LIST_REQUEST,
  GET_CSV_LIST_SUCCESS,
  GET_REQUESTLIST_FAIL,
  GET_REQUESTLIST_REQUEST,
  GET_REQUESTLIST_SUCCESS,
  POST_CARD_FAIL,
  POST_CARD_REQUEST,
  POST_CARD_SUCCESS,
  POST_CSV_LIST_CARDS_FAIL,
  POST_CSV_LIST_CARDS_REQUEST,
  POST_CSV_LIST_CARDS_SUCCESS,
  REREQUEST,
  REREQUEST_FAIL,
  REREQUEST_SUCCESS,
  REVERT_REQUEST,
  REVERT_REQUEST_FAIL,
  REVERT_REQUEST_SUCCESS,
  UPDATE_ADDRESS_INVENTORY,
  UPDATE_ADDRESS_INVENTORY_FAIL,
  UPDATE_ADDRESS_INVENTORY_SUCCESS,
  UPDATE_CARD_FAIL,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
} from "../constants/Cards";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  catagories: [],
  allCards: [],
  totalPages: null,
  csvList: [],
  csvListCards: null,
  csvCardIsPrivate: null,
  unpublishAll: null,
  publishAll: null,
  allTagList: null,
  scheduleAt: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        catagories: action.payload,
      };

    case GET_ALL_CARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_CARDS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_CARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allCards: action.payload.data,
        totalPages: action.payload.total_pages,
        csvCardIsPrivate: action.payload.is_private_room,
        scheduleAt: action.payload.scheduled_at,
        publishAll: action.payload.publish_all,
        unpublishAll: action.payload.unpublish_all,
      };

    case POST_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_CARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case UPDATE_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case REREQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case REREQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case REREQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_CSV_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CSV_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_CSV_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        csvList: action.payload.data,
        totalPages: action.payload.total_pages,
      };

    case POST_CSV_LIST_CARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_CSV_LIST_CARDS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_CSV_LIST_CARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        csvListCards: action.payload.data,
        totalPages: action.payload.total_pages,
      };

    case GET_REQUESTLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REQUESTLIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_REQUESTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allRequest: action.payload.data,
        totalPages: action.payload.total_pages,
        requestStateCounts: action.payload.request_state_counts,
      };
    case REVERT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REVERT_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case REVERT_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ADDRESS_INVENTORY:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ADDRESS_INVENTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case UPDATE_ADDRESS_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
