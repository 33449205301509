import {
   GET_ALL_USER_FAIL,GET_ALL_USER_REQUEST,GET_ALL_USER_SUCCESS,
   UPDATE_USER_FAIL,UPDATE_USER_REQUEST,UPDATE_USER_SUCCESS,
} from "../constants/Users";

const INITIAL_STATE = {
  
  error: false,
  isLoading: false,
  allUser:[],
  totalPages:null

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_ALL_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUser: action.payload.data,
        totalPages: action.payload.total_pages,
        
      };

      case UPDATE_USER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case UPDATE_USER_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
  
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          
        };

      
    default:
      return state;
  }
};
