
//catagory
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export const UPDATE_CARD_FAIL = 'UPDATE_CARD_FAIL';
export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const REREQUEST = "REREQUEST";
export const REREQUEST_FAIL = "REREQUEST_FAIL";
export const REREQUEST_SUCCESS = "REREQUEST_SUCCESS";

export const GET_CARD_FAIL = 'GET_CARD_FAIL';
export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';

export const POST_CARD_FAIL = 'POST_CARD_FAIL';
export const POST_CARD_REQUEST = 'POST_CARD_REQUEST';
export const POST_CARD_SUCCESS  = 'POST_CARD_SUCCESS';

export const GET_ALL_CARDS_FAIL = 'GET_ALL_CARDS_FAIL';
export const GET_ALL_CARDS_REQUEST = 'GET_ALL_CARDS_REQUEST';
export const GET_ALL_CARDS_SUCCESS = 'GET_ALL_CARDS_SUCCESS';

export const GET_CSV_LIST_FAIL = 'GET_CSV_LIST_FAIL';
export const GET_CSV_LIST_REQUEST = 'GET_CSV_LIST_REQUEST';
export const GET_CSV_LIST_SUCCESS = 'GET_CSV_LIST_SUCCESS';

export const POST_CSV_LIST_CARDS_FAIL = 'POST_CSV_LIST_CARDS_FAIL';
export const POST_CSV_LIST_CARDS_REQUEST = 'POST_CSV_LIST_CARDS_REQUEST';
export const POST_CSV_LIST_CARDS_SUCCESS = 'POST_CSV_LIST_CARDS_SUCCESS';

export const GET_REQUESTLIST_SUCCESS = "GET_REQUESTLIST_SUCCESS";
export const GET_REQUESTLIST_REQUEST = "GET_REQUESTLIST_REQUEST";
export const GET_REQUESTLIST_FAIL = "GET_REQUESTLIST_FAIL";

export const REVERT_REQUEST_SUCCESS = "REVERT_REQUEST_SUCCESS";
export const REVERT_REQUEST = "REVERT_REQUEST";
export const REVERT_REQUEST_FAIL = "REVERT_REQUEST_FAIL";

export const UPDATE_ADDRESS_INVENTORY_SUCCESS =
  "UPDATE_ADDRESS_INVENTORY_SUCCESS";
export const UPDATE_ADDRESS_INVENTORY = "UPDATE_ADDRESS_INVENTORY";
export const UPDATE_ADDRESS_INVENTORY_FAIL = "UPDATE_ADDRESS_INVENTORY_FAIL";
