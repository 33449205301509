import { 
  GET_ALL_DASHBOARD_DATA_FAIL,GET_ALL_DASHBOARD_DATA_REQUEST,GET_ALL_DASHBOARD_DATA_SUCCESS,
  GET_SOLD_AND_ORDER_FAIL,GET_SOLD_AND_ORDER_REQUEST,GET_SOLD_AND_ORDER_SUCCESS,
  GET_ORDER_AND_EARNING_FAIL,GET_ORDER_AND_EARNING_REQUEST,GET_ORDER_AND_EARNING_SUCCESS,
  GET_EARNING_FAIL,GET_EARNING_REQUEST,GET_EARNING_SUCCESS,LOGOUT_STATE



  
} from "../constants/Dashboard";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  dashboardData:{},
  soldAndOrder:{},
  orderAndEarning:{},
  earningReports:{}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_STATE:
      return {
        
        isLoading: false,
      };
    case GET_ALL_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload,
      };


      case GET_SOLD_AND_ORDER_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case GET_SOLD_AND_ORDER_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
  
      case GET_SOLD_AND_ORDER_SUCCESS:
        return {
          ...state,
          isLoading: false,
          soldAndOrder: action.payload,
        };

        case GET_ORDER_AND_EARNING_REQUEST:
          return {
            ...state,
            isLoading: true,
          };
        case GET_ORDER_AND_EARNING_FAIL:
          return {
            ...state,
            isLoading: false,
            error: action.error,
          };
    
        case GET_ORDER_AND_EARNING_SUCCESS:
          return {
            ...state,
            isLoading: false,
            orderAndEarning: action.payload,
          };
      
          case GET_EARNING_REQUEST:
            return {
              ...state,
              isLoading: true,
            };
          case GET_EARNING_FAIL:
            return {
              ...state,
              isLoading: false,
              error: action.error,
            };
      
          case GET_EARNING_SUCCESS:
            return {
              ...state,
              isLoading: false,
              earningReports: action.payload,
            };
    default:
      return state;
  }
};
