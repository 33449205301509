import {
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  UPDATE_INVENTORY_REQUEST,
  UPDATE_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  POST_INVENTORY_FAIL,
  POST_INVENTORY_REQUEST,
  POST_INVENTORY_SUCCESS,
  GET_TAG_LIST_REQUEST,
  GET_TAG_LIST_FAIL,
  GET_TAG_LIST_SUCCESS,
} from "../constants/Inventory";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  catagories: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INVENTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        catagories: action.payload,
      };

    case GET_TAG_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TAG_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allTagList: action.payload,
        totalPages: action.payload.total_pages,
      };
    case POST_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_INVENTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_INVENTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};