
export const HOME_PAGE = 'HOME_PAGE';

//Login
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_FAIL = 'POST_LOGIN_FAIL';

export const POST_ADDRESS_SUCCESS = 'POST_ADDRESS_SUCCESS';
export const POST_ADDRESS_REQUEST = 'POST_ADDRESS_REQUEST';
export const POST_ADDRESS_FAIL = 'POST_ADDRESS_FAIL';

export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

export const GET_SELLER_ADDRESS_SUCCESS = 'GET_SELLER_ADDRESS_SUCCESS';
export const GET_SELLER_ADDRESS_REQUEST = 'GET_SELLER_ADDRESS_REQUEST';
export const GET_SELLER_ADDRESS_FAIL = 'GET_SELLER_ADDRESS_FAIL';


export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';

export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS";
export const GET_CARD_REQUEST = "GET_CARD_REQUEST";
export const GET_CARD_FAIL = "GET_CARD_FAIL";

export const POST_ACTIONREQUEST_SUCCESS = "POST_ACTIONREQUEST_SUCCESS";
export const POST_ACTIONREQUEST_REQUEST = "POST_ACTIONREQUEST_REQUEST";
export const POST_ACTIONREQUEST_FAIL = "POST_ACTIONREQUEST_FAIL";
export const CLEAR_CARD_DETAILS = "CLEAR_CARD_DETAILS";

export const GET_REQUESTHISTORY_SUCCESS = "GET_REQUESTHISTORY_SUCCESS";
export const GET_REQUESTHISTORY_REQUEST = "GET_REQUESTHISTORY_REQUEST";
export const GET_REQUESTHISTORY_FAIL = "GET_REQUESTHISTORY_FAIL";


