
//Login
export const GET_ALL_USER_FAIL = 'GET_ALL_USER_FAIL';
export const GET_ALL_USER_REQUEST = 'GET_ALL_USER_REQUEST';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';

export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

