import {
  DELETE_CATAGORY_REQUEST,
  DELETE_CATAGORY_SUCCESS,
  UPDATE_CATAGORY_FAIL,
  UPDATE_CATAGORY_REQUEST,
  UPDATE_CATAGORY_SUCCESS,
  GET_TRACKING_STATUS_FAIL,
  GET_TRACKING_STATUS_REQUEST,
  GET_TRACKING_STATUS_SUCCESS,
  GET_ADMIN_TAX_FAIL,
  GET_ADMIN_TAX_REQUEST,
  GET_ADMIN_TAX_SUCCESS,
  POST_SHIP_OTP_REQ_FAIL,
  POST_SHIP_OTP_REQ_REQUEST,
  POST_SHIP_OTP_REQ_SUCCESS,
  GET_ALL_SHIPMENTS_FAIL,
  GET_ALL_SHIPMENTS_REQUEST,
  GET_ALL_SHIPMENTS_SUCCESS,
  GET_ALL_NEW_SHIPMENTS_FAIL,
  GET_ALL_NEW_SHIPMENTS_REQUEST,
  GET_ALL_NEW_SHIPMENTS_SUCCESS,
  GET_ALL_NEW_SHIPMENTS_CARDS_FAIL,
  GET_ALL_NEW_SHIPMENTS_CARDS_REQUEST,
  GET_ALL_NEW_SHIPMENTS_CARDS_SUCCESS,
  GET_SHIPMENT_STATS,
  GET_SHIPMENT_STATS_SUCCESS,
  GET_SHIPMENT_STATS_FAIL,
  GET_REFUND_RECEIPT_REQUEST,
  GET_REFUND_RECEIPT_FAIL,
  GET_REFUND_RECEIPT_SUCCESS,
  GET_BUYER_RECEIPT_SUCCESS,
  GET_BUYER_RECEIPT_FAIL,
  GET_BUYER_RECEIPT_REQUEST,
  GET_SELLER_PAYOUT_LIST,
  GET_SELLER_PAYOUT_SUCCESS,
  GET_SELLER_PAYOUT_LIST_SUCCESS,
  GET_SELLER_PAYOUT_LIST_FAIL,
  GET_SELLER_PAYOUT_REQUEST,
  GET_SELLER_PAYOUT_FAIL,
  GET_SELLER_PAYOUT_DETAILS,
  GET_SELLER_PAYOUT_DETAILS_FAIL,
  GET_SELLER_PAYOUT_DETAILS_SUCCESS,
} from "../constants/Shipments";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  getTrack: {},
  allShipments: [],
  totalPages: null,
  getAdminTaxData: null,
  allNewShipments: null,
  allNewShipmentCards: null,
  shipmentStats: {},
  paymentReceipt: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRACKING_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TRACKING_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getTrack: action.payload,
      };

    case GET_REFUND_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REFUND_RECEIPT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_REFUND_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentReceipt: action.payload?.data,
      };

    case GET_SELLER_PAYOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SELLER_PAYOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_SELLER_PAYOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentReceipt: action.payload?.data,
      };

    case GET_SELLER_PAYOUT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SELLER_PAYOUT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_SELLER_PAYOUT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_SELLER_PAYOUT_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SELLER_PAYOUT_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_SELLER_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_BUYER_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BUYER_RECEIPT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_BUYER_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentReceipt: action.payload?.data,
      };

    case GET_ALL_SHIPMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ALL_SHIPMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allShipments: action.payload.data,
        totalPages: action.payload.total_pages,
      };

    case GET_SHIPMENT_STATS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SHIPMENT_STATS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_SHIPMENT_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shipmentStats: action.payload.data,
      };

    case POST_SHIP_OTP_REQ_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_SHIP_OTP_REQ_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_SHIP_OTP_REQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CATAGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case UPDATE_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ADMIN_TAX_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADMIN_TAX_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ADMIN_TAX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getAdminTaxData: action.payload.data,
      };

    case GET_ALL_NEW_SHIPMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_NEW_SHIPMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_NEW_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allNewShipments: action.payload.data,
        totalPages: action.payload.total_pages,
      };

    case GET_ALL_NEW_SHIPMENTS_CARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_NEW_SHIPMENTS_CARDS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ALL_NEW_SHIPMENTS_CARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allNewShipmentCards: action.payload.data,
      };

    default:
      return state;
  }
};
