
//catagory
export const DELETE_CATAGORY_REQUEST = 'DELETE_CATAGORY_REQUEST';
export const DELETE_CATAGORY_SUCCESS = 'DELETE_CATAGORY_SUCCESS';

export const UPDATE_CATAGORY_FAIL = 'UPDATE_CATAGORY_FAIL';
export const UPDATE_CATAGORY_REQUEST = 'UPDATE_CATAGORY_REQUEST';
export const UPDATE_CATAGORY_SUCCESS = 'UPDATE_CATAGORY_SUCCESS';

export const GET_CATAGORY_FAIL = 'GET_CATAGORY_FAIL';
export const GET_CATAGORY_REQUEST = 'GET_CATAGORY_REQUEST';
export const GET_CATAGORY_SUCCESS = 'GET_CATAGORY_SUCCESS';

export const POST_CATAGORY_FAIL = 'POST_CATAGORY_FAIL';
export const POST_CATAGORY_REQUEST = 'POST_CATAGORY_REQUEST';
export const POST_CATAGORY_SUCCESS  = 'POST_CATAGORY_SUCCESS';

export const GET_ALL_CATAGORY_FAIL = 'GET_ALL_CATAGORY_FAIL';
export const GET_ALL_CATAGORY_REQUEST = 'GET_ALL_CATAGORY_REQUEST';
export const GET_ALL_CATAGORY_SUCCESS = 'GET_ALL_CATAGORY_SUCCESS';

