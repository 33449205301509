export const DELETE_INVENTORY_REQUEST = "DELETE_INVENTORY_REQUEST";
export const DELETE_INVENTORY_SUCCESS = "DELETE_INVENTORY_SUCCESS";

export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";
export const UPDATE_INVENTORY_REQUEST = "UPDATE_INVENTORY_REQUEST";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";

export const GET_INVENTORY_FAIL = "GET_INVENTORY_FAIL";
export const GET_INVENTORY_REQUEST = "GET_INVENTORY_REQUEST";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";

export const POST_INVENTORY_FAIL = "POST_INVENTORY_FAIL";
export const POST_INVENTORY_REQUEST = "POST_INVENTORY_REQUEST";
export const POST_INVENTORY_SUCCESS = "POST_INVENTORY_SUCCESS";

export const GET_TAG_LIST_FAIL = "GET_TAG_LIST_FAIL";
export const GET_TAG_LIST_REQUEST = "GET_TAG_LIST_REQUEST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
