import { combineReducers } from "redux";
import home from "./HomeReducer";
import catagories from "./CatagoryReducer";
import cards from "./CardsReducer";
import users from "./UserReducer";
import dashboard from "./DashboardReducer";
import shipment from "./ShipmentsReducer";
import inventory from "./InventoryReducer";

export default combineReducers({
  home,
  catagories,
  cards,
  users,
  dashboard,
  shipment,
  inventory
});
