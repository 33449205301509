
//catagory

export const GET_ALL_DASHBOARD_DATA_FAIL = 'GET_ALL_DASHBOARD_DATA_FAIL';
export const GET_ALL_DASHBOARD_DATA_REQUEST = 'GET_ALL_DASHBOARD_DATA_REQUEST';
export const GET_ALL_DASHBOARD_DATA_SUCCESS = 'GET_ALL_DASHBOARD_DATA_SUCCESS';
export const LOGOUT_STATE = 'LOGOUT_STATE';



export const GET_SOLD_AND_ORDER_FAIL = 'GET_SOLD_AND_ORDER_FAIL';
export const GET_SOLD_AND_ORDER_REQUEST = 'GET_SOLD_AND_ORDER_REQUEST';
export const GET_SOLD_AND_ORDER_SUCCESS = 'GET_SOLD_AND_ORDER_SUCCESS';


export const GET_ORDER_AND_EARNING_FAIL = 'GET_ORDER_AND_EARNING_FAIL';
export const GET_ORDER_AND_EARNING_REQUEST = 'GET_ORDER_AND_EARNING_REQUEST';
export const GET_ORDER_AND_EARNING_SUCCESS = 'GET_ORDER_AND_EARNING_SUCCESS';


export const GET_EARNING_FAIL = 'GET_EARNING_FAIL';
export const GET_EARNING_REQUEST = 'GET_EARNING_REQUEST';
export const GET_EARNING_SUCCESS = 'GET_EARNING_SUCCESS';


