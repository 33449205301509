
//catagory
export const DELETE_CATAGORY_REQUEST = 'DELETE_CATAGORY_REQUEST';
export const DELETE_CATAGORY_SUCCESS = 'DELETE_CATAGORY_SUCCESS';

export const UPDATE_CATAGORY_FAIL = 'UPDATE_CATAGORY_FAIL';
export const UPDATE_CATAGORY_REQUEST = 'UPDATE_CATAGORY_REQUEST';
export const UPDATE_CATAGORY_SUCCESS = 'UPDATE_CATAGORY_SUCCESS';

export const GET_TRACKING_STATUS_FAIL = 'GET_TRACKING_STATUS_FAIL';
export const GET_TRACKING_STATUS_REQUEST = 'GET_TRACKING_STATUS_REQUEST';
export const GET_TRACKING_STATUS_SUCCESS = 'GET_TRACKING_STATUS_SUCCESS';

export const GET_REFUND_RECEIPT_FAIL = "GET_REFUND_RECEIPT_FAIL";
export const GET_REFUND_RECEIPT_REQUEST = "GET_REFUND_RECEIPT_REQUEST";
export const GET_REFUND_RECEIPT_SUCCESS = "GET_REFUND_RECEIPT_SUCCESS";

export const GET_SELLER_PAYOUT_LIST_FAIL = "GET_SELLER_PAYOUT_LIST_FAIL";
export const GET_SELLER_PAYOUT_LIST = "GET_SELLER_PAYOUT_LIST";
export const GET_SELLER_PAYOUT_LIST_SUCCESS = "GET_SELLER_PAYOUT_LIST_SUCCESS";

export const GET_SELLER_PAYOUT_DETAILS_FAIL = "GET_SELLER_PAYOUT_DETAILS_FAIL";
export const GET_SELLER_PAYOUT_DETAILS = "GET_SELLER_PAYOUT_DETAILS";
export const GET_SELLER_PAYOUT_DETAILS_SUCCESS =
  "GET_SELLER_PAYOUT_DETAILS_SUCCESS";

export const GET_BUYER_RECEIPT_FAIL = "GET_BUYER_RECEIPT_FAIL";
export const GET_BUYER_RECEIPT_REQUEST = "GET_BUYER_RECEIPT_REQUEST";
export const GET_BUYER_RECEIPT_SUCCESS = "GET_BUYER_RECEIPT_SUCCESS";

export const GET_SELLER_PAYOUT_FAIL = "GET_SELLER_PAYOUT_FAIL";
export const GET_SELLER_PAYOUT_REQUEST = "GET_SELLER_PAYOUT_REQUEST";
export const GET_SELLER_PAYOUT_SUCCESS = "GET_SELLER_PAYOUT_SUCCESS";

export const GET_ADMIN_TAX_FAIL = 'GET_ADMIN_TAX_FAIL';
export const GET_ADMIN_TAX_REQUEST = 'GET_ADMIN_TAX_REQUEST';
export const GET_ADMIN_TAX_SUCCESS = 'GET_ADMIN_TAX_SUCCESS';

export const POST_SHIP_OTP_REQ_FAIL = 'POST_SHIP_OTP_REQ_FAIL';
export const POST_SHIP_OTP_REQ_REQUEST = 'POST_SHIP_OTP_REQ_REQUEST';
export const POST_SHIP_OTP_REQ_SUCCESS  = 'POST_SHIP_OTP_REQ_SUCCESS';

export const GET_ALL_SHIPMENTS_FAIL = 'GET_ALL_SHIPMENTS_FAIL';
export const GET_ALL_SHIPMENTS_REQUEST = 'GET_ALL_SHIPMENTS_REQUEST';
export const GET_ALL_SHIPMENTS_SUCCESS = 'GET_ALL_SHIPMENTS_SUCCESS';


export const GET_SHIPMENT_STATS = "GET_SHIPMENT_STATS";
export const GET_SHIPMENT_STATS_SUCCESS = "GET_SHIPMENT_STATS_SUCCESS";
export const GET_SHIPMENT_STATS_FAIL = "GET_SHIPMENT_STATS_FAIL";

export const GET_ALL_NEW_SHIPMENTS_FAIL = 'GET_ALL_NEW_SHIPMENTS_FAIL';
export const GET_ALL_NEW_SHIPMENTS_REQUEST = 'GET_ALL_NEW_SHIPMENTS_REQUEST';
export const GET_ALL_NEW_SHIPMENTS_SUCCESS = 'GET_ALL_NEW_SHIPMENTS_SUCCESS';

export const GET_ALL_NEW_SHIPMENTS_CARDS_FAIL = 'GET_ALL_NEW_SHIPMENTS_CARDS_FAIL';
export const GET_ALL_NEW_SHIPMENTS_CARDS_REQUEST = 'GET_ALL_NEW_SHIPMENTS_CARDS_REQUEST';
export const GET_ALL_NEW_SHIPMENTS_CARDS_SUCCESS = 'GET_ALL_NEW_SHIPMENTS_CARDS_SUCCESS';

