import {
  DELETE_CATAGORY_REQUEST,
  DELETE_CATAGORY_SUCCESS,
  UPDATE_CATAGORY_FAIL,
  UPDATE_CATAGORY_REQUEST,
  UPDATE_CATAGORY_SUCCESS,
  GET_CATAGORY_FAIL,
  GET_CATAGORY_REQUEST,
  GET_CATAGORY_SUCCESS,
  POST_CATAGORY_FAIL,
  POST_CATAGORY_REQUEST,
  POST_CATAGORY_SUCCESS,
  GET_ALL_CATAGORY_FAIL,GET_ALL_CATAGORY_REQUEST,GET_ALL_CATAGORY_SUCCESS
} from "../constants/Catagory";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  catagories: [],
  allCatagories: [],
  totalPages:null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CATAGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        catagories: action.payload,
      };

      case GET_ALL_CATAGORY_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case GET_ALL_CATAGORY_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
  
      case GET_ALL_CATAGORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          allCatagories: action.payload.data,
          totalPages: action.payload.total_pages,
        };

    case POST_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_CATAGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CATAGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case UPDATE_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_CATAGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CATAGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
